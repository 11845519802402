const cmReporteriaRoutes = [
    {
        path: "/convenio-marco-reporteria",
        name: "convenio-marco-reporteria",
        component: () =>
            import(
                /* webpackChunkName: "creacionConvenio" */ "./reporteComprasView.vue"
                ),
    },
    {
        path: "/procesos-pendientes-contratacion",
        name: "procesos-pendientes-contratacion",
        component: () =>
            import(/* webpackChunkName: "ProcesosPublicosReporteria" */ "./reporteProcesosLibres.vue"),
    },
    {
        path: "/procesos-pendientes-contratacion-copia",
        name: "procesos-pendientes-contratacion-copia",
        component: () =>
            import(/* webpackChunkName: "ProcesosPublicosReporteria" */ "./reporteProcesosLibresCopia.vue"),
    }
];

export default cmReporteriaRoutes;
