import { http_client } from "@/plugins/http_client";

const getNotificaciones = async (params = {}) =>
  http_client(`/api/v1/notificaciones`, params, "get", {}, true, 'json', false, null, null, false);

const readAllNotifications = async (params = {}) =>
  http_client(`/api/v1/notificaciones/readed/all`, params, "put");

const markAsRead = async (id_notificacion, params = {}) =>
  http_client(
    `/api/v1/notificaciones/${id_notificacion}/readed`,
    params,
    "put"
  );

const deleteNotification = async (id_notificacion, params = {}) =>
  http_client(`/api/v1/notificaciones/${id_notificacion}`, params, "delete");

const deleteAllNotifications= async(params)=>http_client('/api/v1/notificaciones/all', params, 'delete')

const enviarNotificaciones = async (params = {}) =>
  http_client(`/api/v1/notificaciones/enviar`, params, "post");

const enviarNotificacionesCorreo = async (params = {}) =>
  http_client(`/api/v1/notificaciones/enviar/correo`, params, "post");

const listarPerfiles = async (params = {paginacion:false}) =>
  http_client(`/api/v1/perfiles`, params, "get", {}, true, 'json', false, null, null, false);

export default {
  getNotificaciones,
  readAllNotifications,
  markAsRead,
  deleteNotification,
  enviarNotificaciones,
  listarPerfiles,
  deleteAllNotifications,
  enviarNotificacionesCorreo
};
