import SeguimientoContractual from "@/services/SeguimientoContractual.services";
import { createLoadable, toggleLoadable, setLoadableResponse } from '@/utils/loadable';

export default {
    namespaced: true,
    state: {
        criterios: createLoadable([]),
        evaluacion: createLoadable([]),
        guardarEvaluacion: {
            notas: [],
            promedioNota: 0,
            observacion: null,
            id_contrato: null,
            id_proveedor: null,
            adjunto: null,
            tieneUnaEvaluacion: false,
        },
        evaluacionDefault: {
            notas: [],
            observacion: '',
            id_contrato: '',
            id_proveedor: '',
            adjunto: null,
            promedioNota: 0,
            tieneUnaEvaluacion: false,
        },
    },
    mutations: {
        obtenerCriterios(state, data) {
            state.criterios = data;
            toggleLoadable(state.criterios);
        },
        enviarEvaluacion(state, data) {
            state.evaluacion = data;
            toggleLoadable(state.evaluacion);
        },
        resetEvaluacion(state){
            state.guardarEvaluacion = { ...state.evaluacionDefault }
        }
    },
    actions: {
        cargarCriterios: async ({ commit, state }) => {
            toggleLoadable(state.criterios);
            const { data } = await SeguimientoContractual.obtenerCriterios();
            commit('obtenerCriterios', data);
            setLoadableResponse(state.criterios, data);
        },
        
        // async enviarEvaluacion({ commit, state }, evaluacionCalificacion) {
        //         toggleLoadable(state.evaluacion);
        //         const { data } = await SeguimientoContractual.crearEvaluacion(evaluacionCalificacion);
        //         commit('enviarEvaluacion', data);
        //         setLoadableResponse(state.evaluacion, data);
        // },
        resetForm({commit}) {
            commit('resetEvaluacion');
        }
    },
}
