import { http_client, http_client_with_status } from "@/plugins/http_client";

const http_client_slim = (url, params = {}, method = "get") =>
  http_client(`/api/v1/autenticacion/${url}`, params, method);

const http_client_with_status_slim = (url, params = {}, method = "get") =>
  http_client_with_status(`/api/v1/autenticacion/${url}`, params, method);

const obtenerQr = async (params = {}) => http_client_slim("obtener/qr");

const validarMetodo = async (params = {}) =>
  http_client_slim("validar/metodo", params, "patch");

const cancelarTOPT = async (params = {}) =>
  http_client_slim("validar/metodo", params, "delete");

const ingresarTopt = async (body = {}) =>
  http_client_with_status_slim("validar/authenticator", body, "post");

const obtenerMetodosUsuario = async (id_usuario, params = {}) =>
  http_client_slim(`metodos/${id_usuario}`, params, "get");

const actualizarMetodo = async (id_usuario, id_metodo, params = {}) =>
  http_client_slim(`metodo/actualizar/${id_usuario}/${id_metodo}`, params, "put");

const eliminarMetodo = async (id_metodo, params = {}) =>
  http_client_slim(`metodo/eliminar/${id_metodo}`, params, "delete");

export default {
  obtenerQr,
  validarMetodo,
  cancelarTOPT,
  ingresarTopt,
  obtenerMetodosUsuario,
  actualizarMetodo,
  eliminarMetodo
};
