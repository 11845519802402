import { http_client } from "@/plugins/http_client";

const reqGetBlackList = async (filters = {}) =>
  await http_client("/api/v1/proveedores/listas/negra", filters);
const reqGetListSancProv = async (id_proveedor, filters = {}) =>
  await http_client(
    `/api/v1/proveedores/listas/negra/${id_proveedor}/sanciones`,
    filters
  );
const reqGetDetailProv = async (id_proveedor, filters = {}) =>
  await http_client(
    `/api/v1/proveedores/listas/negra/${id_proveedor}`,
    filters
  );
const reqGetDetailSanc = async (id_sancion, filters = {}) =>
  await http_client(
    `/api/v1/proveedores/listas/negra/sancion/${id_sancion}`,
    filters
  );

const getLeyesList = async (params = {}) => {
  return await http_client(`/api/v1/ley-sanciones`, params, "get");
};

const postLeyes = async (params = {}) => {
  return await http_client(`/api/v1/ley-sanciones`, params, "post");
};

const putLeyes = async (id_ley, params = {}) => {
  return await http_client(`/api/v1/ley-sanciones/${id_ley}`, params, "put");
};

const deleteLeyes = async (id_ley, params = {}) => {
  return await http_client(`/api/v1/ley-sanciones/${id_ley}`, params, "delete");
};

const getLey = async (id_ley, params = {}) => {
  return await http_client(`/api/v1/ley-sanciones/${id_ley}`, params, "get");
};

const getDescripcionSanciones = async (params = {}) => {
  return await http_client(`/api/v1/descripcion-sanciones`, params, "get");
};

const postDescripcionSanciones = async (params = {}) => {
  return await http_client(`/api/v1/descripcion-sanciones`, params, "post");
};

const putDescripcionSanciones = async (id_descripcion, params = {}) => {
  return await http_client(
    `/api/v1/descripcion-sanciones/${id_descripcion}`,
    params,
    "put"
  );
};

const deleteDescripcionSanciones = async (id_descripcion, params = {}) => {
  return await http_client(
    `/api/v1/descripcion-sanciones/${id_descripcion}`,
    params,
    "delete"
  );
};

const setEstadoSancion = async (id_sancion, body = {}) =>
  await http_client(`/api/v1/sanciones/${id_sancion}/estados`, body, "put");

const postReporteSancion = async (body = {}) => {
  return await http_client("/api/v1/reportes/sanciones", body, "post");
};

const deleteReporteSancion = async (id_reporte, params = {}) => {
  return await http_client(
    `/api/v1/reportes/sanciones/${id_reporte}`,
    params,
    "delete"
  );
};

const getReporteSancion = async (id_contrato_orden, params = {}) => {
  return await http_client(
    `/api/v1/reportes/sanciones/${id_contrato_orden}`,
    params,
    "get"
  );
};

const getReporteSancionInstitucion = async (params = {}) => {
  return await http_client(
    "/api/v1/reportes/sanciones/institucion",
    params,
    "get"
  );
};

const showReporteSancion = async (id_reporte_sancion, params = {}) => {
  return await http_client(
    `/api/v1/reportes/sanciones/show/${id_reporte_sancion}`,
    params,
    "get"
  );
};

const descargarArchivo = async (params = {}) => {
  return await http_client(
    "/api/v1/documentos/descargar",
    params,
    "get",
    {},
    true,
    "blob"
  );
};

const putEstadoReporte = async (id_reporte, params = {}) => {
  return await http_client(
    `/api/v1/reportes/sanciones/${id_reporte}`,
    params,
    "put"
  );
};

const getAllSancionesPreRegistro = async (params = {}) => {
  return await http_client(
    "/api/v1/sanciones/proveedores/no-registrados",
    params,
    "get"
  );
};

const postSancionPreRegistro = async (params = {}) => {
  return await http_client(
    "/api/v1/sanciones/proveedores/no-registrados",
    params,
    "post"
  );
};

const getSancionPreRegistro = async (id_sancion, params = {}) => {
  return await http_client(
    `/api/v1/sanciones/proveedores/no-registrados/${id_sancion}`,
    params,
    "get"
  );
};

const putSancionPrePregistro = async (id_sancion, params = {}) => {
  return await http_client(
    `/api/v1/sanciones/proveedores/no-registrados/${id_sancion}`,
    params,
    "put"
  );
};

const subirCsvSanciones = async (params = {}) => {
  return await http_client(
    `/api/v1/sanciones/proveeedores/no-registrados/cargaCsv`,
    params,
    "post"
  );
};

const deleteRegistroSancion = async (id_sancion, params = {}) => {
  return await http_client(
    `/api/v1/sanciones/proveedores/no-registrados/delete/${id_sancion}`,
    params,
    "post",
    { "Content-Type": "multipart/form-data" }
  );
};

const postSancionHomologada = async (params = {}) => {
  return await http_client(
    "/api/v1/sanciones/provedores/homologados",
    params,
    "post"
  );
};

const getSancionHomologadaById = async (id_proveedor, params = {}) => {
  return await http_client(
    `/api/v1/sanciones/proveedores/homologados/${id_proveedor}`,
    params,
    "get"
  );
};

const putSancionHomologadaByid = async (id_sancion, params = {}) => {
  return await http_client(
    `/api/v1/sanciones/proveedores/homologados/${id_sancion}`,
    params,
    "put"
  );
};

const deleteInhabilitacionProveedorRegistrado = async (
  id_sancion,
  params = {}
) => {
  return await http_client(
    `/api/v1/sanciones/proveedores/homologados/delete/${id_sancion}`,
    params,
    "post",
    { "Content-Type": "multipart/form-data" }
  );
};

const deleteListaSanciones = async (id_sancion, fuente, params = {}) => {
  return await http_client(
    `/api/v1/proveedores/listas/negra/lista-sanciones/eliminar/${id_sancion}/${fuente}`,
    params,
    "post",
    { "Content-Type": "multipart/form-data" }
  );
};

const getInstitucionUsuarioCatalogo = async (params = {}) => {
  return await http_client(
    `/api/v1/sanciones/institucion-usuario/catalogo`,
    params,
    "get"
  );
};

const obtenerTiposSanciones = async () =>
  http_client("/api/v1/sanciones/listar/tipos/sanciones", {}, "get");

export default {
  reqGetBlackList,
  reqGetListSancProv,
  reqGetDetailProv,
  reqGetDetailSanc,
  setEstadoSancion,
  getLeyesList,
  postLeyes,
  putLeyes,
  deleteLeyes,
  getLey,
  getDescripcionSanciones,
  postDescripcionSanciones,
  putDescripcionSanciones,
  deleteDescripcionSanciones,
  postReporteSancion,
  deleteReporteSancion,
  getReporteSancion,
  getReporteSancionInstitucion,
  showReporteSancion,
  descargarArchivo,
  putEstadoReporte,
  getAllSancionesPreRegistro,
  postSancionPreRegistro,
  getSancionPreRegistro,
  putSancionPrePregistro,
  subirCsvSanciones,
  deleteRegistroSancion,
  getSancionHomologadaById,
  postSancionHomologada,
  putSancionHomologadaByid,
  deleteListaSanciones,
  deleteInhabilitacionProveedorRegistrado,
  getInstitucionUsuarioCatalogo,
  obtenerTiposSanciones
};
