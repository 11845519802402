const seguimientoProcesosRoutes = [
  {
    path: "/seguimiento-procesos",
    name: "seguimiento-procesos",
    component: () =>
      import(
        /* webpackChunkName: "seguimientoProcesos" */ "./seguimientoProcesos.vue"
      ),
  },
  {
    path: "/seguimiento-procesos/detalle/:id_seguimiento_proceso",
    name: "seguimiento-procesos-detalle",
    component: () =>
      import(
        /* webpackChunkName: "detalleSeguimientoProceso" */ "./detalleSeguimientoProceso.vue"
      ),
  },
  {
    path: "/modificar-contrato/:id_contrato",
    name: "modificar-contrato",
    component: () =>
      import(
        /* webpackChunkName: "modificativa" */ "./edicionContrato/modificativas.vue"
      ),
  },
  {
    path: "/prorroga-contrato/:id_contrato",
    name: "prorroga-contrato",
    component: () =>
      import(
        /* webpackChunkName: "prorroga" */ "./edicionContrato/prorroga.vue"
      ),
  },
];

export default seguimientoProcesosRoutes;
