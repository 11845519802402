import { http_client, http_client_with_status } from "@/plugins/http_client";

const http_client_slim = async (url, params, method = "get") =>
  http_client(`/api/v1/edicion/contrato/${url}`, params, method);

const tiposDocumentosEdicion = async (params = {}) =>
  http_client_slim(`listar/tipos/documentos`, params);

const enviarEdicionContrato = async (id_contrato, id_modificativa, body = {}) =>
  http_client_slim(`${id_contrato}/${id_modificativa}`, body, "POST");

const obtenerLotesEtapa = async (id_contrato, params = {}) =>
  http_client_slim(`${id_contrato}/etapas/lotes`, params);

const obtenerContrato = async (id_contrato, params = {}) =>
  http_client_slim(`${id_contrato}/contrato`, params);

const obtenerHistorial = (id_contrato, tipo_edicion) => 
  http_client_with_status(`/api/v1/edicion/contrato/${id_contrato}/${tipo_edicion}/historial`, null, 'get');

export default {
  tiposDocumentosEdicion,
  enviarEdicionContrato,
  obtenerLotesEtapa,
  obtenerContrato,
  obtenerHistorial,
};
