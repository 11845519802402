import { http_client } from "../plugins/http_client";

const http_client_slim = async (url, parametros = {}, metodo = "get") =>
  http_client(`/api/v1/reinicios/subasta/${url}`, parametros, metodo);

const listSubastas = async (id_proceso_compra, params) =>
  http_client_slim(`${id_proceso_compra}`, params);

const eliminarSubasta = async (id_proceso_compra, id_subasta) =>
  http_client_slim(`${id_proceso_compra}/${id_subasta}`, {}, "delete");

export default { listSubastas, eliminarSubasta };
