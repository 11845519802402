import {http_client, http_client_with_status} from "@/plugins/http_client";

const obtenerlistadoProveedores = async(filters) => await http_client_with_status('/api/v1/listado-proov/login-listar-proveedores', filters, 'get');

const obtenerlistadoSancionesProveedores = async(filters) => await http_client_with_status('/api/v1/listado-proov/login-listar-sanciones-proveedores', filters, 'get');

export default{
    obtenerlistadoProveedores,
    obtenerlistadoSancionesProveedores,
}
