<template>
    <div>
        <v-simple-table v-if="offers.length > 0" class="cart-items-table">
            <template v-slot:default>
            <thead>
                <tr>
                    <th style="width: 50px;" class="text-center">Foto</th>
                    <th style="min-width: 75px;" class="text-center">Nombre</th>
                    <th style="min-width: 75px;" class="text-center">Proveedor</th>
                    <th v-if="!isProductRequest" style="min-width: 75px;" class="text-center">Dirección</th>
                    <th v-if="!validacionMonto" style="min-width: 30px;" class="text-center">Precio</th>
                    <th style="min-width: 30px;" class="text-center">Cantidad</th>
                    <th v-if="isProductRequest" style="min-width: 30px;" class="text-center">Sub total</th>
                    <th 
                        style="width: 100px;" 
                        class="fixed-column text-center" 
                        v-if="!isRequestExpired"
                    >
                        Acciones
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="offer in offers"
                    :key="offer.id"
                >
                    <td class="text-center">
                        <v-img 
                            max-height="45" 
                            max-width="45" 
                            :src="offer.cmopc.CMProducto.foto" 
                        />
                    </td>
                    <td class="text-center">
                        <router-link :to="`${baseUrl}/${offer.cmopc.CMProducto.id}`">
                            {{ offer.cmopc.CMProducto.nombre }}
                        </router-link>
                    </td>
                    <td class="text-center">
                        {{ offer.cmopc.Proveedor.nombre }}
                    </td>
                    <td v-if="!isProductRequest" class="text-center">{{ offer.direccion ?? '-'}}</td>
                    <td v-if="!validacionMonto" class="text-center">${{ offer.precio_de_oferta }}</td>
                    <td class="text-center">{{ offer.cantidad }}</td>
                    <td v-if="isProductRequest" class="text-center">${{ offer.subtotal }}</td>
                    <!-- TODO: SE COMENTO EL BOTON DE EDICION POR LOS MULTIPLES FONDOS (REVISAR EN UN FUTURO JEJE)                   -->
                    <td :class="{ 'fixed-column': !isEditAction }" v-if="!isRequestExpired">
                        <div class="cart-items-table-actions">
                            <!-- <v-icon v-if="offer.id_tipo_prod === 2 && (esCombustible(offer ) || esProductoHijo(offer))"  x-small color="primary" class="ml-2" @click.stop="setActiveOffer(offer, isEditAction)">mdi mdi-pencil</v-icon>
                            <v-icon v-if="offer.id_tipo_prod !== 2 && (esCombustible(offer) && esProductoHijo(offer))"  x-small color="primary" class="ml-2" @click.stop="setActiveOffer(offer, isEditAction)">mdi mdi-pencil</v-icon>
                            <v-icon v-if="offer.id_tipo_prod === 1 && !(esCombustible(offer) || esProductoHijo(offer))"  x-small color="primary" class="ml-2" @click.stop="setActiveOffer(offer, isEditAction)">mdi mdi-pencil</v-icon> -->
                            <v-icon x-small class="ml-1" color="secondary" @click.stop="confirmOfferRemove(offer)">mdi mdi-alpha-x-circle</v-icon>
                        </div>
                    </td>
                </tr>
            </tbody>
            </template>
        </v-simple-table>
        <b class="cart-overlay-total my-10" v-if="offers.length > 0">${{ total }}</b>
        <ConfirmationModalComponent 
            :isOpen="confirmationOpen" 
            description="¿Desea continuar con la acción?" 
            @confirm="handleConfirmation" 
            @cancel="confirmationOpen = false"  
            :isLoading="itemAction.isLoading"
        />

        <!-- Cart item edit -->
        <UpsertCartItemComponent 
            :isOpen="isEditModalOpen" 
            :offerMeta="offerToEdit"
            :fundsLoadable="funds"
            isEditing
            justForm
            @open-change="toggleEditModal"
            @save="collectEditData" 
            :retryFundsLoading="retryCartProductFundsFetch"
        />
    </div>
</template>
<script>
import { ConfirmationModalComponent } from '@/components/utils/';
import { UpsertCartItemComponent } from '@/components/catalogoEnLinea/purchaseFlow';
import { Fragment } from 'vue-fragment';
import { Observable } from '@/utils/observable.js';
import { CMConstants } from '@/utils/cm-constants.js';
import { createLoadable, toggleLoadable, setLoadableResponse, isResponseSuccesful, shouldRetry } from '@/utils/loadable';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
    name: 'CartItemsTableComponent',
    props: {
        offers: { type: Array, required: true },
        isRequestExpired: { type: Boolean },
        request: { type: Object, required: true },
        isEditAction: { type: Boolean, default: false},
        baseUrl: { type: String, required:true}
    },
    components: { ConfirmationModalComponent, UpsertCartItemComponent, Fragment },
    data: () => ({
        currentAction: null,
        confirmationOpen: false,
        itemAction: createLoadable(null),
        isEditModalOpen: false,
        offerToEdit: {},
        activeOffer: null,
        ofertaEdicionActiva: null,
    }),
    methods: {
        ...mapMutations("catalogoEnLinea",
            ["removeItemFromCart", "setFeedbackModalConfig", "resetCartProductFundsState",
            "resetCartProductFundsStateEdit","eliminarAgendamiento"]),
        ...mapActions("catalogoEnLinea", ["updateItemWithNewQuantity", "fetchCartProductFunds","fetchCartProductFundsEdit"]),
        handleFeedbackModalClose() {
            this.currentAction = null;
        },
        showFeedback(message) {
            this.activeOffer = null;
            this.setFeedbackModalConfig({
                isOpen: true,
                type: "success", 
                description: message,
                buttonText: "Aceptar",
                onClose: this.handleFeedbackModalClose,
            });   
        },
        handleConfirmation() {
            if (this.agendamiento) {
                this.agregarAgendamiento({ 
                    idProducto: this.offerMeta.id_producto, 
                    agendamiento: { ...this.agendamiento },
                })
            }
            if (!this.currentAction) return;
            return this.currentAction === 'remove' ? this.handleOfferRemove() : this.updateOffer();
        },
        // remove
        confirmOfferRemove(offer) {
            this.currentAction = 'remove';
            this.activeOffer = offer;
            this.confirmationOpen = true;
        },
        async handleOfferRemove() {
            toggleLoadable(this.itemAction);
            const offerId = this.activeOffer.id;
            const { data } = await this.services.PurchaseRequest.deleteOffertFromRequest(offerId, this.request.id);

            this.agendamientos.forEach(agendamiento => {
                this.agendamientoList = agendamiento
            });

            this.eliminarAgendamiento({
                idProducto: this.activeOffer.cmopc.CMProducto.id,
                agendamiento: { ...this.agendamientoList },
            })

            setLoadableResponse(this.itemAction, data, { skipOnSuccess: true });
            this.confirmationOpen = false;
            if (!isResponseSuccesful(data)) return;
            
            if(this.isEditAction){
                Observable.emit('offert-delete-change',this.activeOffer);
            }else{
                // this.removeItemFromCart(this.activeOffer);
                Observable.emit('offerts-change');
                Observable.emit('actualizar-carrito');
            }
            this.showFeedback(data.message);
        },
        // edit
        toggleEditModal() {
            this.isEditModalOpen = !this.isEditModalOpen;
        },
        handleOfferUpdate(update) {
            this.updateItemWithNewQuantity({ 
                update: update.objetoActualizado, 
                disponibilidad: update.oferta_actualizada.disponibilidad 
            });
        },
        esCombustible(offer){
            const cantidad = offer.cmopc.CMProducto.CmSubGrupo.cantidad
            const cifra = offer.cmopc.CMProducto.CmSubGrupo.cifra
            const vigencia = offer.cmopc.CMProducto.CmSubGrupo.vigencia
            const monto = offer.cmopc.CMProducto.CmSubGrupo.monto

            return (cifra && vigencia && (cantidad || monto))
        },
        esProductoHijo(offer){
            return !!offer.id_producto_solicitud 
        },
        setActiveOffer(offer) {
            this.currentAction = 'edit';
            this.isEditModalOpen = true;
            this.ofertaEdicionActiva = offer;

            const direcciones = offer.cmopc?.cm_do?.map((d) => ({
                id: d.id,
                direccion: d.direccion,
            })); 

            const offerObject = {
                disponibilidad: offer.cmopc.disponibilidad,
                reservado: offer.cantidad,
                id_oferta: offer.cmopc.id,
                tipo_oferta: offer.cmopc.CMProducto.id_tipo_producto,
                id_producto: offer.cmopc.CMProducto.id,
                precio: offer.precio_de_oferta,
                id_fondo_solicitud_necesidad: offer.id_fondo_solicitud_necesidad,
                id_asignacion_presupuestaria: offer.id_asignacion_presupuestaria,
                direcciones,
                idProductoSolicitud: offer.id,
                direccion: offer.direccion,
                manejo_altura: offer.cmopc.CMProducto.CmSubGrupo.altura,
                manejo_base: offer.cmopc.CMProducto.CmSubGrupo.base,
                manejo_cantidad: offer.cmopc.CMProducto.CmSubGrupo.cantidad,
                cantidad: offer.cmopc.CMProducto.CmSubGrupo.cantidad,
                manejo_altura_decimal: offer.cmopc.CMProducto.CmSubGrupo.altura_decimal,
                manejo_base_decimal: offer.cmopc.CMProducto.CmSubGrupo.base_decimal,
                manejo_existencias: offer.cmopc.CMProducto.CmSubGrupo.manejo_existencias,
                cantidad_decimal: offer.cmopc.CMProducto.CmSubGrupo.cantidad_decimal,
                manejo_direccion: offer.cmopc.CMProducto.CmSubGrupo.direccion,
                manejo_fecha: offer.cmopc.CMProducto.CmSubGrupo.fecha,
                manejo_hora: offer.cmopc.CMProducto.CmSubGrupo.hora,
                manejo_pdf: offer.cmopc.CMProducto.CmSubGrupo.pdf,
                limite_altura: offer.cmopc.CMProducto.CmSubGrupo.limite_altura,
                limite_base: offer.cmopc.CMProducto.CmSubGrupo.limite_base,
                altura: offer.cmopc.cmsc?.altura,
                base: offer.cmopc.cmsc?.base,
                comentario: offer.cmopc.cmsc.comentario,
                fecha_agendamiento: offer?.CmAgendamiento?.fecha_agendamiento,
                hora_agendamiento: offer?.CmAgendamiento?.hora_agendamiento,
                manejo_monto: offer.cmopc.CMProducto.CmSubGrupo.monto,
                monto: offer.cmopc.cmsc?.monto,
                manejo_vigencia: offer.cmopc.CMProducto.CmSubGrupo.vigencia,
                vigencia: offer.cmopc.cmsc?.vigencia,
                manejo_cifra: offer.cmopc.CMProducto.CmSubGrupo.cifra,
                cifra: offer.cmopc.cmsc?.cifra,
            }

            this.offerToEdit = offerObject;
            if (this.isEditAction) {
                this.resetCartProductFundsStateEdit();
                this.fetchCartProductFundsEdit({productId:offerObject.id_producto ,requestId: this.request.id,});
                return;
            }

            this.resetCartProductFundsState();
            this.fetchCartProductFunds(offerObject.id_producto);
        },
        retryCartProductFundsFetch() {
            if (!shouldRetry(this.cartProductFunds)) return;

            this.fetchCartProductFunds(this.offerToEdit.id_producto);
        },
        collectEditData(data) {
            this.isEditModalOpen = false;
            this.activeOffer = data;
            this.confirmationOpen = true;
        },
        async updateOffer() {
            toggleLoadable(this.itemAction);
            this.activeOffer.append('editar', true);

            const endpoint = this.ofertaEdicionActiva.id_tipo_prod === 2 
                ? this.services.PurchaseRequest.upsertServiceToRequest
                : this.services.PurchaseRequest.upsertProductToRequest;

            const { data } = await endpoint(this.activeOffer,this.request.id);
            setLoadableResponse(this.itemAction, data, true);
            this.confirmationOpen = false;
            if (!isResponseSuccesful(data)) return;

            if(this.isEditAction){
                Observable.emit('offert-change-on-edit-request',data.data);
            }else{
                this.handleOfferUpdate(data.data);
                Observable.emit('offerts-change');
            }
            this.showFeedback(data.message);
        },
    },
    computed: {
        ...mapState("catalogoEnLinea", ["inCartRequest", "cartProductFunds","cartProductFundsEdit","agendamientos"]),
        total() {
            const amount = this.$props.offers.reduce((prev, offer) => prev + Number(offer.subtotal), 0);
            return amount.toFixed(2);
        },
        isProductRequest() {
            return this.request?.id_tipo_solicitud === CMConstants.TIPO_PRODUCTO;
        },
        funds(){
            return this.isEditAction ? this.cartProductFundsEdit : this.cartProductFunds
        },
        validacionMonto(){ 
            return this.$props.offers[0].cmopc.CMProducto.CmSubGrupo.monto
        }
    },
    beforeDestroy() {
        this.resetCartProductFundsState();
        this.resetCartProductFundsStateEdit();
    },
};
</script>
<style>
.cart-items-table table {
    border-collapse: separate !important;
    border-spacing: 0 4px !important;
}

.cart-items-table .v-data-table__wrapper tbody tr:hover td {
  background-color: #eee !important;
}

.cart-items-table .cart-items-table-actions {
    display: flex;
    justify-content: center;
}

.cart-items-table  .cart-items-table-actions .v-icon {
    font-size: 24px !important;
}

.cart-items-table .fixed-column {
    position: sticky;
    right: 0;
    z-index: 1;
    background-color: #fff;
}

</style>