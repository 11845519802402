const { http_client_with_status } = require("@/plugins/http_client");

const cargarCifradosCertificaciones = (idProcesoCompra) => http_client_with_status(
    `/api/v1/refinanciamiento-compras/certificacion/${idProcesoCompra}`,
    null, 
    'get'
);

const visualizarDocumentoCertificacion = (idProcesoCompra, id_certificacion) => http_client_with_status(
    `/api/v1/refinanciamiento-compras/certificacion/visualizar/${idProcesoCompra}/${id_certificacion}`,
    null,
    'get',
    { },
    { responseType: "blob" },
);

const crearCertificacion = (idProcesoCompra, idFondoRefinanciamiento, data) => http_client_with_status(
    `/api/v1/refinanciamiento-compras/certificacion/crear/${idProcesoCompra}/${idFondoRefinanciamiento}`,
    data,
    'post',
    { 'Content-Type': 'multipart/form-data' },
);

const eliminarCertificacion = (idProcesoCompra, idCertificacion) => http_client_with_status(
    `/api/v1/refinanciamiento-compras/certificacion/eliminar/${idProcesoCompra}/${idCertificacion}`,
    null,
    'delete',
);

export default {
    cargarCifradosCertificaciones,
    visualizarDocumentoCertificacion,
    crearCertificacion,
    eliminarCertificacion,
}
