import { http_client } from "@/plugins/http_client";

const http_client_slim = (url, params = {}, method = "get") =>
  http_client(`/api/v1/administracion/correo/${url}`, params, method);

const obtenerConfiguracionUsuario = async (id_usuario, params = {}) =>
  http_client_slim(`${id_usuario}`, params);

const configurarMetodoCorreo = async (id_usuario, id_metodo, params = {}) =>
  http_client_slim(`${id_usuario}/${id_metodo}`, params, 'put');

export default {
  obtenerConfiguracionUsuario,
  configurarMetodoCorreo,
};
