import { http_client } from "../plugins/http_client";

const http_client_slim = async (url, parametros = {}, metodo = "get") =>
  http_client(`/api/v1/modificacion/procesos/${url}`, parametros, metodo);

const validarModificativa = async (idProceso, idModificativa) =>
  http_client_slim(`buscar/${idProceso}/${idModificativa}`);

const subirModificativa = async (idProceso, idModificativa, body) =>
  http_client_slim(`${idProceso}/${idModificativa}`, body, "post");

export default {
  validarModificativa,
  subirModificativa,
};
