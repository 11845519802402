const rutasRefinanciamientoCompras = [
    {
        path: "/refinanciamiento-compras",
        name: "refinanciamiento-compras",
        component: () => import(/* webpackChunkName: "refinanciamientoCompras" */ "./index.vue"),
    },
    {
        path: "/refinanciamiento-compras/vinculaciones/:id_proceso_compra",
        name: 'refinanciamiento-compras-vinculaciones',
        component: () => import(/* webpackChunkName: "refinanciamientoComprasVinculaciones" */ "./vinculacionesView.vue"),
    },
    {
        path: "/refinanciamiento-compras/refinanciar/:id_proceso_compra",
        name: 'refinanciar-proceso-compra',
        component: () => import(/* webpackChunkName: "refinanciarProcesoCompra" */ "./refinanciarProcesoCompra.vue"),
    },
    {
        path: "/refinanciamiento-compras/refinanciar/ajustar/:id_proceso_compra/:id_refinanciamiento",
        name: 'ajustar-refinanciamiento',
        component: () => import(/* webpackChunkName: "ajustarRefinanciamiento" */ "./ajustarRefinanciamiento.vue"),
    },
    {
        path: "/refinanciamiento-compras/certificar/:id_proceso_compra",
        name: 'certificar-refinanciamientos',
        component: () => import(/* webpackChunkName: "certificarRefinanciamientos" */ "./certificarRefinanciamientos.vue"),
    },
];

export default rutasRefinanciamientoCompras;