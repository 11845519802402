import subastaInversaRoute from "./SubastaInversa/router";

const routerCompras = [
  {
    path: "/proceso-compra/aprobar-solicitud",
    name: "aprobar-solicitud-compra",
    component: () =>
      import(
        /* webpackChunkName: "aprobarSolicitudCompras" */ "./aprobarSolicitudCompraView.vue"
      ),
  },
  {
    path: "/proceso-compra/ufi-aprobar-solicitud",
    name: "ufi-aprobar-solicitud-compra",
    component: () =>
      import(
        /* webpackChunkName: "ufiAprobarSolicitudCompras" */ "./ufiAprobarSolicitudCompraView.vue"
      ),
  },
  {
    path: "/proceso-compra/uaci-aprobar-solicitud",
    name: "uaci-aprobar-solicitud-compra",
    component: () =>
      import(
        /* webpackChunkName: "uaciAprobarSolicitudCompras" */ "./aprobarSolicitudCompraView.vue"
      ),
  },
  {
    path: "/proceso-compra/seguimiento-orden/:idContrato/:idProveedor?",
    name: "seguimiento-orden-compra",
    component: () =>
      import(
        /* webpackChunkName: "seguimientoOrdenCompras" */ "./seguimientoOrdenCompraView.vue"
      ),
  },
  {
    path: "/proceso-compra/seguimiento-orden-v1/:idContrato/:idProveedor?",
    name: "seguimiento-orden-compra-v1",
    component: () =>
      import(
        /* webpackChunkName: "seguimientoOrdenComprasV1" */ "./seguimientoOrdenCompraViewV1.vue"
      ),
  },
  {
    path: "/proceso-compra/entrega-etapa/:idContrato/:idEtapa?",
    name: "entrega-etapa-compra",
    component: () =>
      import(
        /* webpackChunkName: "entregaEtapaCompras" */ "./entregaEtapaCompraView.vue"
      ),
  },
  {
    path: "/proceso-compra/liquidacion-orden/:idContrato/:onlyView?",
    name: "liquidacion-orden-compra",
    component: () =>
      import(
        /* webpackChunkName: "liquidacionOrdenCompra" */ "./liquidacionOrdenCompraView.vue"
      ),
  },
  {
    path: "/proceso-compra/seguimiento-proceso",
    name: "seguimiento-proceso-compra",
    component: () =>
      import(
        /* webpackChunkName: "seguimientoProcesoCompra" */ "./seguimientoProcesoCompraView.vue"
      ),
  },
  {
    path: "/proceso-compra/configuracion-proceso",
    name: "configuracion-proceso-compra-old",
    component: () =>
      import(
        /* webpackChunkName: "configuracionProcesoComprapc" */ "./configuracionProcesoCompraView.vue"
      ),
  },
  {
    path: "/proceso-compra/publicacion-proceso",
    name: "publicacion-proceso-compra",
    component: () =>
      import(
        /* webpackChunkName: "publicacionProcesoCompra" */ "./publicacionProcesoCompraView.vue"
      ),
  },
  {
    path: "/proceso-compra/visualizacion-proceso",
    name: "visualizacion-proceso-compra",
    component: () =>
      import(
        /* webpackChunkName: "visualizacionProcesoCompra" */ "./visualizacionProcesoCompraView.vue"
      ),
  },
  {
    path: "/proceso-compra/agregar-insumo",
    name: "agregar-insumo-compra",
    component: () =>
      import(
        /* webpackChunkName: "agregarInsumoCompra" */ "./agregarInsumoCompraView.vue"
      ),
  },
  {
    path: "/proceso-compra/uaci-verificar-proveedor",
    name: "verificar-proveedor-compra",
    component: () =>
      import(
        /* webpackChunkName: "verificacionProveedor" */ "./verificacionProveedorView.vue"
      ),
  },
  {
    path: "/proceso-compra/recepcion-total/:id_orden/:idProveedor?/:id_etapa?",
    name: "recepcion-compra",
    component: () =>
      import(
        /* webpackChunkName: "recepcionTotal" */ "./SeguimientoContractual/recepcionTotal.vue"
      ),
  },
  {
    path: "/proceso-compra/recepcion-bienes/:id_orden/:idProveedor?/:id_etapa?",
    name: "recepcion-bienes",
    component: () =>
      import(
        /* webpackChunkName: "recepcionParcialBienes" */ "./SeguimientoContractual/recepcionParcialBienes.vue"
      ),
  },
  {
    path: "/proceso-compra/recepcion-obras-servicios/:id_orden/:idProveedor?/:id_etapa?",
    name: "recepcion-bienes",
    component: () =>
      import(
        /* webpackChunkName: "recepcionParcialObrasServicios" */ "./SeguimientoContractual/recepcionParcialObrasServicios.vue"
      ),
  },
  {
    path: "/proceso-compra/detalles-insumo",
    name: "detalles-insumo-compra",
    component: () =>
      import(
        /* webpackChunkName: "detallesInsumo" */ "./detallesInsumoView.vue"
      ),
  },
  ...subastaInversaRoute,
];

export default routerCompras;
