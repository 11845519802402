import { http_client } from "@/plugins/http_client";

const http_client_slim = (url, params = {}, method = "get") =>
  http_client(`/api/v1/envio/correos/proveedor/${url}`, params, method);

const obtenerProveedores = async (id_proceso_compra, params = {}) =>
  http_client_slim(`proveedores/${id_proceso_compra}`);

const enviarNotificacion = async (
  id_proceso_compra,
  id_proveedor,
  params = {}
) => http_client_slim(`${id_proceso_compra}/${id_proveedor}`, params, "post");

export default { obtenerProveedores, enviarNotificacion };
