<template>
    <div v-if="loading" class="d-flex align-center" :class="justifyContent" style="gap: 16px;">
        <v-progress-circular indeterminate color="primary" :size="20" :width="2"></v-progress-circular>
        <p class="mb-0 font-weight-medium">{{ text }}</p>
    </div>
</template>
<script>
export default {
    name: 'InlineLoader',
    props: {
        loading: { type: Boolean },
        text: { type: String },
        align: { type: String, default: 'start' }
    },
    computed: {
        justifyContent() {
            switch (this.align) {
                case 'end':
                    return 'justify-end';
                case 'center':
                    return 'justify-center';
                default: 
                    return 'justify-start';
            }
        },
    },
}
</script>