<template>
  <v-dialog v-model="showDialog" persistent max-width="700" min-height="700">
    <v-card class="pa-4" max-width="700">
      <v-card-title justify="center">
        <v-row justify="center">
          <div><v-col>Agregar aplicación de Autenticación</v-col></div>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row justify="center">
          <div>
            <v-img :src="foto" width="400" contain />
          </div>
        </v-row>
        <v-row>
          <v-otp-input
            v-model="code"
            length="6"
            outlined
            style="width: 100%"
            @finish="() => (modalConfirmacion = true)"
          />
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="12" md="12">
            <v-btn block @click.stop="close" class="primary">Cancelar</v-btn>
          </v-col>
          <!-- <v-col cols="6" md="6">
            <v-btn @click="modalConfirmacion = true" block class="secondary">
              Finalizar configuración
            </v-btn>
          </v-col> -->
        </v-row>
      </v-card-actions>
    </v-card>
    <ConfirmationDialog
      :show="modalConfirmacion"
      title="¿Deseas finalizar la configuración?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Continuar"
      @close="modalConfirmacion = false"
      @confirm="validar()"
    />
  </v-dialog>
</template>
<script>
import ConfirmationDialog from "@/components/ConfirmationDialogComponent.vue";

export default {
  name: "agregarToptComponent",
  components: {
    ConfirmationDialog,
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    foto: null,
    modalConfirmacion: false,
    code: null,
  }),
  methods: {
    close(agregado = false) {
      this.foto = null;
      this.modalConfirmacion = false;
      this.code = null;
      if(agregado){
      this.services.Autenticacion.cancelarTOPT();
      }
      this.$emit("close");
    },
    async obtenerQr() {
      const { data } = await this.services.Autenticacion.obtenerQr().catch(
        (err) => {
          this.close(true);
          throw err;
        }
      );
      this.foto = data;
    },
    async validar() {
      await this.services.Autenticacion.validarMetodo({ code: this.code });
      this.close();
    },
  },
  watch: {
    showDialog: async function (valor) {
      if (valor) {
        await this.obtenerQr();
      }
    },
  },
};
</script>
