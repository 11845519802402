const routerAdminContratos = [
  {
    path: "/contratos-asignados",
    name: "contratos-asignados",
    component: () =>
      import(
        /* webpackChunkName: "procesosAsignados" */ "./ProcesosAsignadosView.vue"
      ),
  },
  {
    path: "/contratos-liquidar",
    name: "contratos-liquidar",
    component: () =>
      import(
        /* webpackChunkName: "contratosRecepcion" */ "../ProcesoCompra/SeguimientoContractual/contratosRecepcion.vue"
      ),
  },
];

export default routerAdminContratos;
