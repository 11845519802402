import { http_client } from "../plugins/http_client";

const http_client_slim = async (url, parametros = {}, metodo = "get") =>
  http_client(`/api/v1/adjudicaciones/${url}`, parametros, metodo);

const listAdjudicaciones = async (id_proceso_compra, parametros) =>
  http_client_slim(`${id_proceso_compra}`, parametros);

const listProveedores = async (id_proceso_compra, parametros) =>
  http_client_slim(`${id_proceso_compra}/proveedores`, parametros);

const listLotes = async (id_proceso_compra, id_proveedor, parametros) =>
  http_client_slim(
    `${id_proceso_compra}/lotes${id_proveedor ? `/${id_proveedor}` : ""}`,
    parametros
  );

const cambiarProveedor = async (
  id_sub_proceso_ganador,
  id_proceso_compra,
  parametros
) =>
  http_client_slim(
    `${id_sub_proceso_ganador}/${id_proceso_compra}`,
    parametros,
    "patch"
  );

const eliminarAdjudicacion = async (
  id_sub_proceso_ganador,
  id_proceso_compra,
  parametros
) =>
  http_client_slim(
    `${id_sub_proceso_ganador}/${id_proceso_compra}`,
    parametros,
    "delete"
  );

const obtenerDetalleLote = async (id) => http_client_slim(`lotes/${id}`);

const adjudicar = async (id_proceso_compra, id_proveedor, body) =>
  http_client_slim(`${id_proceso_compra}/${id_proveedor}`, body, "post");

export default {
  listAdjudicaciones,
  listProveedores,
  listLotes,
  cambiarProveedor,
  eliminarAdjudicacion,
  obtenerDetalleLote,
  adjudicar
};
