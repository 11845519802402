export default () => ({
  cargandoContrato: true,
  seguimientoContrato: null,
  entregaEtapa: {
    monto: null,
    fecha_hora_movimiento: null,
    justificacion: null,
    id_tipo_movimiento: null,
    id_metodo_pago: null,
    id_institucion_financiera: null,
    numero_cheque: null,
    a_nombre_de: null,
    ponderacion: null,
    observaciones: null,
    documento: null,
    comprobante: null,
    acta_inconformidad: null,
    acta_recepcion: null,
  },
  editEntrega: false,
  entregaLista: false,
  pagoEtapa: {
    id: null,
    id_etapa: null,
    fecha: null,
    hora: null,
    monto: null,
    id_tipo_movimiento: null,
    justificacion: null,
    id_metodo_pago: null,
    id_institucion_financiera: null,
    numero_cheque: null,
    a_nombre_de: null,
    fecha_hora: null,
    comprobante: null,
  },
  pagoEditable: false,
  liquidacion_compra: {
    observaciones: null,
    acta_liquidacion: null,
  },
  montos: {},
  pagos: [],
  sanciones: [],
  filtrosPagos: {
    page: 1,
    per_page: 10,
    total_rows: null,
    id_tipo_movimiento: null,
  },
  filtrosSanciones: {
    page: 1,
    per_page: 10,
    total_rows: null,
  },
});
