export const obtenerContrato = (state, val) => {
  state.seguimientoContrato = val;
};

export const getEntregaData = (state, payload) => {
  Object.keys(state.entregaEtapa).forEach((key) => {
    state.entregaEtapa[key] = payload[key];
  });
};

export const setCargandoContrato = (state, val) => {
  state.cargandoContrato = val;
};

export const clearEntregaData = (state) => {
  Object.keys(state.entregaEtapa).forEach((key) => {
    state.entregaEtapa[key] = null;
  });
};

export const setEntregaEditable = (state, payload) => {
  state.editEntrega = payload;
};

export const setEntregaLista = (state, payload) => {
  state.entregaLista = payload;
};

export const getPagoEtapa = (state, payload) => {
  Object.keys(state.pagoEtapa).forEach((key) => {
    state.pagoEtapa[key] = payload[key];
  });
};

export const clearPagoEtapa = (state) => {
  Object.keys(state.pagoEtapa).forEach((key) => {
    state.pagoEtapa[key] = null;
  });
};

export const getPagoEditable = (state, payload) => {
  state.pagoEditable = payload;
};

export const setObservacionesLiquidacion = (state, payload) => {
  state.liquidacion_compra.observaciones = payload;
};

export const setMontos = (state, payload) => {
  state.montos = payload;
};

export const setPagos = (state, { data, headers }) => {
  state.pagos = data;
  state.filtrosPagos = {
    page: Number(headers.page),
    per_page: Number(headers.per_page),
    total_rows: Number(headers.total_rows),
  };
};

export const setSanciones = (state, { data, headers }) => {
  state.sanciones = data;
  state.filtrosSanciones = {
    page: Number(headers.page),
    per_page: Number(headers.per_page),
    total_rows: Number(headers.total_rows),
  };
};

export const setFiltrosPagos = (state, { page, per_page, total_rows }) => {
  state.filtrosPagos = {
    page: Number(page),
    per_page: Number(per_page),
  };

  if (total_rows) state.filtrosPagos.total_rows = Number(total_rows);
};

export const setFiltrosSanciones = (state, { page, per_page, total_rows }) => {
  state.filtrosSanciones = {
    page: Number(page),
    per_page: Number(per_page),
  };

  if (total_rows) state.filtrosSanciones.total_rows = Number(total_rows);
};
