import { http_client_with_status, http_client } from "@/plugins/http_client";

const obtenerTipoRecepcion = async (params = {}) =>
  http_client_with_status(
    "/api/v1/seguimiento/contractual/tipo/recepcion",
    params,
    "get"
  );

const obtenerTiposRecepciones = async (params = {}) =>
  http_client("/api/v1/contratacion/tipos/recepciones", params, "get");

const obtenerCriterios = async (params = {}) => {
  return http_client_with_status(
    "/api/v1/seguimiento/contractual/criterios",
    params,
    "get"
  );
};

const obtenerLotesEtapa = async (id_contrato, id_etapa) =>
  await http_client_with_status(
    `/api/v1/seguimiento/contractual/${id_contrato}/etapas/lotes${
      id_etapa ? `/${id_etapa}` : ""
    }`,
    {}
  );

const guardarContratoRecepcion = async (id_contrato, params = {}) => {
  return await http_client_with_status(
    `/api/v1/seguimiento/contractual/${id_contrato}/recepcion`,
    params,
    "post"
  );
};

const crearEvaluacion = async (params = {}) => {
  return await http_client_with_status(
    `/api/v1/seguimiento/contractual/evaluacion`,
    params,
    "post"
  );
};

const cambioEstadoRecepcion = async (id_recepcion, params) =>
  http_client_with_status(
    `/api/v1/seguimiento/contractual/${id_recepcion}/cambio/estado`,
    params,
    "post"
  );

const obtenerContratos = async (params) =>
  http_client_with_status(
    `/api/v1/seguimiento/contractual/obtener/contratos`,
    params,
    "get"
  );
const obtenerTipoMovimiento = async (params) =>
  http_client_with_status(
    `/api/v1/seguimiento/contractual/obtener/tipo/movimiento`,
    params,
    "get"
  );

const actualizarTipoRecepcion = async (
  id_contrato,
  id_etapa = {},
  params = {}
) =>
  http_client(
    `/api/v1/seguimiento/contractual/${id_contrato}/actualizar/${id_etapa}/recepcion`,
    params,
    "put"
  );

const obtenerTotalRecepcionado = async (id_contrato) =>
  http_client(
    `/api/v1/seguimiento/contractual/${id_contrato}/obtener/total/recepcionado`,
    {},
    "get"
  );
const guardarNota = async (id_etapa,body) =>
  http_client(
    `/api/v1/seguimiento/contractual/${id_etapa}/crear/nota`,
    body,
    "post",
    {
      'Content-Type': 'multipart/form-data',
    }
  );

const obtenerDocumentosRecepcion = async (id_recepcion) =>
  http_client_with_status(
    `/api/v1/seguimiento/contractual/obtener/documentos-recepcion/${id_recepcion}`,
    null,
    "get"
  );

const visualizacionDocumentos = (params) => http_client_with_status(`/api/v1/seguimiento/contractual/visualizar-documento`, params, 'get', {}, { responseType: 'blob' });



export default {
  obtenerCriterios,
  obtenerTipoRecepcion,
  obtenerLotesEtapa,
  guardarContratoRecepcion,
  crearEvaluacion,
  cambioEstadoRecepcion,
  obtenerContratos,
  obtenerTipoMovimiento,
  obtenerTiposRecepciones,
  actualizarTipoRecepcion,
  obtenerTotalRecepcionado,
  guardarNota,
  obtenerDocumentosRecepcion,
  visualizacionDocumentos
};
